import { ValueType } from '@snailycad/types';

function isPenalCodeValue(value) {
  return "warningApplicableId" in value && "warningNotApplicableId" in value && "descriptionData" in value;
}
function isBaseValue(value) {
  return !isPenalCodeValue(value) && "createdAt" in value && typeof value.type === "string";
}
function hasValueObj(value) {
  return !isPenalCodeValue(value) && "value" in value && typeof value.value === "object";
}
function isVehicleValue(value) {
  return hasValueObj(value) && value.value.type === ValueType.VEHICLE;
}
function isWeaponValue(value) {
  return hasValueObj(value) && value.value.type === ValueType.WEAPON;
}
function isStatusValue(value) {
  return hasValueObj(value) && value.value.type === ValueType.CODES_10;
}
function isDepartmentValue(value) {
  return hasValueObj(value) && value.value.type === ValueType.DEPARTMENT;
}
function isDivisionValue(value) {
  return hasValueObj(value) && value.value.type === ValueType.DIVISION;
}
function isEmployeeValue(value) {
  return hasValueObj(value) && value.value.type === ValueType.BUSINESS_ROLE;
}
function isUnitQualification(value) {
  return hasValueObj(value) && value.value.type === ValueType.QUALIFICATION;
}
function isDLCategoryValue(value) {
  return hasValueObj(value) && value.value.type === ValueType.DRIVERSLICENSE_CATEGORY;
}
function isCallTypeValue(value) {
  return hasValueObj(value) && value.value.type === ValueType.CALL_TYPE;
}
function isAddressValue(value) {
  return hasValueObj(value) && value.value.type === ValueType.ADDRESS;
}
function isEmergencyVehicleValue(value) {
  return hasValueObj(value) && value.value.type === ValueType.EMERGENCY_VEHICLE;
}
function isOfficerRankValue(value) {
  return isBaseValue(value) && value.type === ValueType.OFFICER_RANK;
}
function isUnitCombined(unit) {
  return !("citizenId" in unit) && "officers" in unit;
}
function isUnitCombinedEmsFd(unit) {
  return !("citizenId" in unit) && "deputies" in unit;
}
function isUnitOfficer(unit) {
  return !isUnitCombined(unit) && "divisions" in unit && Array.isArray(unit.divisions);
}

export { hasValueObj, isAddressValue, isBaseValue, isCallTypeValue, isDLCategoryValue, isDepartmentValue, isDivisionValue, isEmergencyVehicleValue, isEmployeeValue, isOfficerRankValue, isPenalCodeValue, isStatusValue, isUnitCombined, isUnitCombinedEmsFd, isUnitOfficer, isUnitQualification, isVehicleValue, isWeaponValue };
