var SocketEvents = /* @__PURE__ */ ((SocketEvents2) => {
  SocketEvents2["CreateTowCall"] = "CreateTowCall";
  SocketEvents2["UpdateTowCall"] = "UpdateTowCall";
  SocketEvents2["EndTowCall"] = "EndTowCall";
  SocketEvents2["CreateTaxiCall"] = "CreateTaxiCall";
  SocketEvents2["UpdateTaxiCall"] = "UpdateTaxiCall";
  SocketEvents2["EndTaxiCall"] = "EndTaxiCall";
  SocketEvents2["Create911Call"] = "Create911Call";
  SocketEvents2["Update911Call"] = "Update911Call";
  SocketEvents2["End911Call"] = "End911Call";
  SocketEvents2["CreateBolo"] = "CreateBolo";
  SocketEvents2["UpdateBolo"] = "UpdateBolo";
  SocketEvents2["DeleteBolo"] = "DeleteBolo";
  SocketEvents2["UpdateAreaOfPlay"] = "UpdateAreaOfPlay";
  SocketEvents2["UpdateOfficerStatus"] = "UpdateOfficerStatus";
  SocketEvents2["UpdateEmsFdStatus"] = "UpdateEmsFdStatus";
  SocketEvents2["UpdateDispatchersState"] = "UpdateDispatchersState";
  SocketEvents2["SetUnitOffDuty"] = "SetUnitOffDuty";
  SocketEvents2["UpdateUnitStatus"] = "UpdateUnitStatus";
  SocketEvents2["UserBanned"] = "UserBanned";
  SocketEvents2["UserDeleted"] = "UserDeleted";
  SocketEvents2["Signal100"] = "Signal100";
  SocketEvents2["PANIC_BUTTON_ON"] = "PANIC_BUTTON_ON";
  SocketEvents2["PANIC_BUTTON_OFF"] = "PANIC_BUTTON_OFF";
  SocketEvents2["CreateActiveIncident"] = "CreateActiveIncident";
  SocketEvents2["UpdateActiveIncident"] = "UpdateActiveIncident";
  SocketEvents2["CreateActiveWarrant"] = "CreateActiveWarrant";
  SocketEvents2["UpdateActiveWarrant"] = "UpdateActiveWarrant";
  SocketEvents2["RoleplayStopped"] = "RoleplayStopped";
  SocketEvents2["Tones"] = "Tones";
  SocketEvents2["PrivateMessage"] = "PrivateMessage";
  return SocketEvents2;
})(SocketEvents || {});

export { SocketEvents };
